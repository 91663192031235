.content {

    &__fileInput {
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    &__file {
        position: absolute;
        top: 50%;
    }

    &__input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px dashed black;
        border-radius: 4px;
        background: #FFFFFF;
        height: 100px;
        width: 100%;
        position: relative;
    }
}