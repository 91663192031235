.header {
    width: 100%;

    &__menu-box {
        position: relative;
        width: 100%;
        background: var(--white);
        box-shadow: 0px 4px 4px rgba(119, 119, 119, 0.3);
        padding: 18px 20px;
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
    }

    &__sub-navigation {
        display: flex;
        background: var(--white);
        padding: 15px 0;
        justify-content: center;
    }

    .breadcrumbs {
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        color: var(--mice);
        padding: 40px 0;
        font-size: var(--size-xxl);
    }
    
    &__logo{
        vertical-align: middle;
        border-style: none;
        width: 70px;
        height: auto;
    }
}