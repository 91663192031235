.stars {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    left: 0;
    top: 0;
}

.star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--gray);
    gap: 5px;
    width: 20px;
    text-align: center;

    &--gray-gradient {

        path {
            fill: url(#gradient_gray);
        }
    }

    &--orange {

        path {
            fill: var(--orange)
        }

        &-gradient {

            path {
                fill: url(#gradient_orange);
            }
        }
    }

    &--lemon {

        path {
            fill: var(--lemon)
        }

        &-gradient {

            path {
                fill: url(#gradient_yellow);
            }
        }
    }

    &--lime {

        path {
            fill: var(--lime)
        }

        &-gradient {

            path {
                fill: url(#gradient_green);
            }
        }
    }

    &--green {

        path {
            fill: var(--green)
        }
    }
}
