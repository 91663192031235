.wrapper {
    position: relative;
    min-height: 100%;
    background: var(--milk);
    width: 100%;
}

.page {

    &__content{
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 20px 60px;
    }
}