* {
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Ubuntu", serif;
    text-decoration: none;
}

html, body, #root {
    height: 100%;
}

body {
    font-size: 16px;
}

button {
    cursor: pointer;
    border: 0;
    background: transparent;
}

section {
    margin-bottom: 60px;
}

.section-title {
    font-size: var(--size-xl);
    color: var(--gray);
    text-align: center;
    margin: 0 0 20px;
    font-weight: 500;
}

.table-title {
    @extend .section-title;
    position: sticky;
    top: 10px;
}

p {
    padding: 0;
    margin-bottom: 0;
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

*::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
}

*::-webkit-scrollbar {
    appearance: none;
}

*::-webkit-scrollbar:vertical {
    width: 11px;
    padding: 0;
}

*::-webkit-scrollbar:horizontal {
    height: 11px;
}

@font-face {
    font-family: Ubuntu;
    src: url("./assets/fonts/Ubuntu-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: Ubuntu;
    src: url("./assets/fonts/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: Roboto;
    src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: Roboto;
    src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: Roboto;
    src: url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
    font-weight: 200;
}