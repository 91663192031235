.navigation {

    &__menu {
        display: flex;
        justify-content: center;
        margin: 0px;
    }

    &__menu-item {
        margin: 0 30px;
    }

    &__menu-link_active {
        color: var(--mice)!important;

        svg {
            fill: var(--mice)!important;
        }
    }

    &__menu-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--size-xxs);
        text-decoration: none;
        color: var(--nav);
        transition: .3s;

        svg {
            fill: var(--nav);
            transition: .3s;
        }

        &:hover {
            color: var(--mice);

            svg {
                fill: var(--mice);
            }
    
        }

    }

}