.about-card {
  background-color: #ffffff;
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  &_big {
    height: 200px;
  }
  
  &_small {
    height: 90px;
  }
  
  &__title, &__value {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  &__title {
    color: #A6A6A6;
    margin-bottom: 12px;
  }

  &_double &__title {
    margin-bottom: 8px;
  }

  &__value {
    color: #777777;
  }

  &__update-btn {
    font-size: 18px;
    color: #37798E;
    font-weight: 500;
    border: none;
    background-color: transparent;
    margin-top: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  &__content_green &__title, 
  &__content_green &__value, {
    color: #76C944;
  }

  &__content_license &__title {
    margin-top: 12px;
  }
}