.condition {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--gray);
    padding: 5px;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 40px;
    }

    &__prior {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 560px;
        max-height: 260px;
        padding: 20px;
        gap: 30px;
        background-color: var(--white);

        svg {
            width: 100%;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.miniPrior {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 60px;
    max-width: 560px;
    gap: 30px;
    padding: 20px;
    background-color: var(--white);

    p {
        margin-bottom: 0;
    }

    &__left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        svg {
            width: 30px;
        }
    }

    &__rating {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 10px;
        padding: 8px;
        border-left: 1px solid var(--mice);
    }
}

.transparent {
    background-color: var(--lightgray);
}
