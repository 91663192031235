.draggable {

    &__container {
        display: flex;
        width: fit-content;
        margin: 40px auto 0;
    }

    &__title {
        font-family: 'Ubuntu', serif;
        font-style: normal;
        font-weight: 500;
        font-size: var(--size-s);
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #777777;
        width: fit-content;
        margin: 0 auto;
    }

    &__field {
        width: 280px;
        height: 340px;
        overflow-y: auto;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 30px;
        margin-top: 10px;
    }

    &__column {
        display: flex;
        align-items: center;
    }

    &__img {
        margin: 24px;
    }

    &__item {

        text-align: center;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 4px 4px rgba(119, 119, 119, 0.3);
        border-radius: 4px;
        margin-bottom: 20px;

        &-text {
            font-family: 'Ubuntu', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #777777;
            padding: 3px;
            margin-bottom: 0;
        }

        &-margin {
            padding: 12px 25px;
        }

        &-selected {
            display: flex;
            flex-direction: column;
            
            > p {
                font-size: 12px;
                line-height: 14px;
                align-items: center;
                color: #777777;
                margin-bottom: 0;
            }
        }
    }
}
