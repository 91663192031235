.user-edit__right-column > div {
    margin-bottom: 40px;
}

.user-filter {
    max-width: 480px;

    &__add {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__item {
        min-width: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &__input {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 5px;
            background: var(--white);
            padding-right: 5px;
        }

        &__title {
            font-weight: 500;
            font-size: 18px;
            color: var(--gray);
        }

        &__box {
            display: flex;
            gap: 20px;
        }
    }

    &__value {
        max-width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: var(--white);
        gap: 5px;

        &__box {
            display: flex;
            gap: 10px;
            width: 100%;
        }
    }
}