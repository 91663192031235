.modal-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    right: 0;
    animation: show .3s;
    z-index: 9999;
    @media (min-width: 1200px) {
     
        &__select {
            position: relative; 
        }
        
    }

    .modal-content {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        @media (min-width: 1200px) {
            width: 80%;
            height: 80%;
            max-height: 80%;

            &__select {
                position: relative; 
            }
            
        }
        background: #F1F1F1;
        border: 1px solid gray;
        border-radius: 15px;
        padding: 0 10px 10px;
        overflow: hidden;

        .modal-control {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .modal-close {
                display: flex;
                align-self: flex-end;
                height: 30px;
                line-height: 0;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
}

@keyframes show {
    from{opacity: 0;}
    to{opacity: 1;}
}
