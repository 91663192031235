.tabs {
    width: 100%;

    &__main {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        width: 100%;
        box-shadow: 0 4px 4px rgba(119, 119, 119, 0.3);
    }

    &__sub {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        width: 100%;
    }

    &__tab {
        color: var(--sea);
        font-size: var(--size-s);
        cursor: pointer;
        transition: .3s;
        padding: 20px;

        &_small {
            font-size: var(--size-xs);
            padding: 15px;
        }

        &:hover {
            color: var(--mice);
        }

        &_active {
            color: var(--mice);
        }
    }
}
