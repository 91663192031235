.field-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    position: relative;

    &__title {
        margin-bottom: 10px;
        text-align: center;
        color: var(--gray);
        font-weight: 500;
    }

    &__input {
        height: 50px;
        border-radius: 4px;
        background: var(--white);
        border: 0;
        text-align: center;
        color: var(--gray);
        font-size: var(--size-xs);
    }
}

.field-icon {
    right: 4%;
    top: 60%;
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

.select {
    width: 100%;
    appearance: none;
    position: relative;

    &__title {
        text-align: center;
        margin-bottom: 10px!important;
        font-weight: 500;
        color: var(--gray);

        &-flex {
            display: flex;
        }
    }

    &__value {
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        min-height: 50px;
        border-radius: 4px;
        background: var(--white);
        border: 0;
        color: var(--gray);
        font-size: var(--size-xs);
        cursor: pointer;
        margin-bottom: 0;
        gap: 5px;
        padding: 5px;
        flex-wrap: wrap;

        svg {
            transform: rotate(-90deg);
            stroke: var(--sea);
            position: absolute;
            right: 10px;
            transition: .3s;
        }

        &_active {

            svg {
                transform: rotate(90deg);
            }
        }

        > p {
            margin-bottom: 0;
            line-height: normal;
            text-align: center;
        }
    }

    &__options {
        position: absolute;
        z-index: 1;
        color: var(--gray);
        background: var(--white);
        border-radius: 4px;
        width: 100%;
        cursor: pointer;
        max-height: 250px;
        overflow-y: overlay;
        border: 1px solid var(--sea);
        transition: .1s;
        opacity: 0;
        visibility: hidden;

        &_active {
            opacity: 1;
            visibility: visible;
        }

        li {
            padding: 10px 0 10px 10px;
            transition: .1s;

            &:hover {
                background: var(--milk);
            }
        }
    }

    &__dropdown {
        position: absolute;
        z-index: 1;
        transition: .1s;
        opacity: 0;
        display: none;

        &-left {
            right: 0;
        }

        &_active {
            opacity: 1;
            display: block;
        }
    }
}

.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    color: var(--gray);
    font-size: var(--size-xs);
    user-select: none;
    min-height: 25px;
    line-height: var(--size-xs);
    flex-direction: column;

    &-disabled {
        cursor: auto;
    }

    &__text {
        line-height: var(--size-s);
    }

    &__title {
        font-size: 1.125em;
        margin-bottom: 12px;
        font-weight: 500;
    }

    &__content {
        display: flex;
        width: 100%;
        flex: 1;
        align-items: center;
    }

    &__checkmark {
        display: flex;
        align-items: center;
        position: relative;
        height: 14px;
        min-width: 14px;
        background-color: var(--white);
        margin-right: 14px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: 10;

        &:checked ~ .checkbox__checkmark {
            background-color: #2196F3;
        }

        &:checked ~ .checkbox__checkmark:after {
            display: block;
        }
    }

    &:hover input ~ .checkbox__checkmark {
        background-color: #CCC;
    }
}

.range-slider {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 4px;
    padding: 40px 0;

    &__children {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__value {
        position: absolute;
        top: 72px;
        width: 8px;
        height: 8px;
        display: none;
        font-family: 'Ubuntu', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #A6A6A6;
        text-align: center;
    }

    &:hover {

        .range-slider__value {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    input {
        width: 100%;
        background: transparent;
        appearance: none;
        height: 4px;
        z-index: 5;
    }

    &__steps {
        position: absolute;
        top: 20px;
        display: flex;
        justify-content: space-between;
        width: calc(100% + 13px);
    }

    input[type=range] {
        height: 14px;
        appearance: none;
        margin: 10px 0;
        width: 100%;
    }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: var(--sea);
        border-radius: 50px;
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: 0 0 0 7px rgba(66, 177, 212, 0.2);
        height: 8px;
        width: 8px;
        border-radius: 50px;
        background: var(--sea);
        cursor: pointer;
        appearance: none;
        margin-top: -2px;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: var(--sea);
    }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: var(--sea);
        border-radius: 50px;
    }

    input[type=range]::-moz-range-thumb {
        height: 8px;
        width: 8px;
        border-radius: 50px;
        background: #37798E;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type=range]::-ms-fill-lower {
        background: var(--sea);
        border-radius: 100px;
    }

    input[type=range]::-ms-fill-upper {
        background: var(--sea);
        border-radius: 100px;
    }

    input[type=range]::-ms-thumb {
        margin-top: 1px;
        height: 8px;
        width: 8px;
        border-radius: 50px;
        background: var(--sea);
        cursor: pointer;
    }

    input[type=range]:focus::-ms-fill-lower {
        background: var(--sea);
    }

    input[type=range]:focus::-ms-fill-upper {
        background: var(--sea);
    }

    &__scale {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-between;
        top: 57px;
        z-index: 1;

        div {
            width: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p {
            font-family: 'Ubuntu', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #A6A6A6;
            margin-top: 4px;
        }
    }
}
