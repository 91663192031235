.service-polls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        border-radius: 4px;
        width: 20px;
        height: 20px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__search {
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 15px;
        align-items: center;
        padding: 5px;

        &:focus {
            outline: none;
        }
    }

    &__table {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &--top {
        margin-bottom: 20px;
    }

    &__left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 75%;
    }

    &__title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: var(--gray);
    }

    &__text {
        margin: 10px 0;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: var(--gray);
    }
}

.connected-question {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
    text-align: start;
    margin: 6px 0;
}

.button-wrapper {
    display: flex;
    padding-top: 5%;
    margin-left: 10px;
}

.edit-wrapper {
    display: flex;
    padding-top: 3%;
}