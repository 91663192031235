.storage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 60px;

    &__settings {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 40px;
        width: 100%;
    }

    &__inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;

        &--alone {
            justify-content: flex-start;
        }
    }

    &__checkbox-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

    }

    &__date-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: var(--gray);
    }

    &__date {
        text-align: center;
    }

    &__sync {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    &__button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 60px;
    }

    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-direction: column;
    }
}

.info-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px 25px 0 25px;
    background-color: #E5E5E5;
    gap: 100px;

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 40px;
        color: var(--mice);
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
    }

    &__subtitle {
        color: var(--gray);
        font-size: 14px;
    }

    &__left {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
    }

    &__right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}