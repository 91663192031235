.about {

    &__cards {
        padding: 20px 0;
        display: grid;
        grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
        grid-column-gap: 20px;
        grid-row-gap: 40px;
        grid-auto-rows: minmax(100px, auto);
        font-size: var(--size-xl);
        color: var(--gray);
        text-align: center;
        margin: 0 0 20px;
    }

    &__parameters {
        max-width: 780px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
    }

    &__parameter {
        max-width: 780px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px 0;
        font-weight: 500;
        font-size: 18px;
        color: #A6A6A6;
    }
}
