.table-box {
    border: none;
    border-collapse: collapse;
    height: fit-content;
    width: 100%;
}

.table-info {
    text-align: center;
    font-size: var(--size-m);
    margin: 20px 0 0;
    color: var(--gray);
}

.outlined {
    border: 3px solid blue;
}

.flex-table__header {
    font-size: 14px;
    font-weight: 300;
    color: #5C5C5C;
    height: fit-content;
}

.table-container {
    margin: 2em auto;
    width: 90%;
    max-width: 600px;
}

.flag-icon {
    margin-right: 0.1em;
}

.flex-row {
    padding: 10px 0.5em;
    font-size: 18px;
    color: var(--gray);
}

.rowspan {
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
}

.column {
    display: flex;
    flex-flow: column wrap;
    width: 75%;
    padding: 0;

    .flex-row {
        flex-flow: row wrap;
        width: 100%;
        padding: 0;
        border: 0;

        &:hover {
            background: #F5F5F5;
            transition: 500ms;
        }
    }
}

.flex-cell {
    width: calc(100% / 3);
    text-align: center;
    padding: 0.5em 0.5em;
}

.flex-table {

    &:hover {
        background: #F5F5F5;
        transition: 500ms;
    }
}

.flex-table__row {
    max-height: 50px;
    height: fit-content;

    &:nth-child(even) {
        background: var(--white);
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin: 45px auto 40px;

    &__count {
        display: flex;

        p {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: var(--size-xs);
            color: var(--gray);
            margin: 0 14px 0 0;
        }

        ul {
            display: flex;
        }

    }

    &__pages {
        display: flex;
        max-width: 80%;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: var(--sea);
        background: var(--white);
        margin: 0 6px;
        cursor: pointer;
        user-select: none;
        transition: .3s;
        border-radius: 4px;

        &_active {
            color: var(--mice);
        }

        svg {
            stroke: var(--nav);
            transition: .3s;
        }

        &:hover {
            color: var(--mice);

            svg {
                stroke: var(--mice);
            }
        }
    }
}

@media all and (max-width: 767px) {

    .flex-row {
        width: calc(100% / 3);

        &.first {
            width: 100%;
        }
    }

    .column {
        width: 100%;
    }
}

.ant__table {

    p {
        margin-bottom: 0;
    }
}