.form-delete {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.4);
    z-index: 999;

    &__title {
        font-size: var(--size-m);
        text-align: center;
        color: var(--white);
    }

    &__buttons {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        width: 100%;
          
        button {
            margin: 0 5px;
            text-align: center;
            border-radius: 4px;
            background-color: var(--sea);
            color: var(--white);
            border: 1px solid #337AB7;
            padding: 5px;
            max-width: 120px;
            width: 100%;
            font-size: var(--size-s);
        }
    }
}