.workplaces {

    .add-btn {
        margin: 30px 0 45px;
    }

    .editable-item {

        &__form-column label {

            margin: 0 0 20px;
        }

        &__form-rows {
            justify-content: space-between;
        }
    }

    .multiselect {

        &__body {
            display: grid;
            grid-row-gap: 20px;
            grid-template-columns: 200px 200px 200px;
            grid-column-gap: 15px;
            height: 220px;
        }
    }

}

.filter {
    max-width: 360px;
    width: 100%;
    padding: 20px;
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 20px;
    break-inside: avoid;
    background: var(--white);

    &_transparent {
        background: var(--milk);

        .checkbox__checkmark {
            background: var(--white);
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    &__content {
        display: flex;
        flex-direction: column;

        .checkbox {
            margin: 0 0 20px;
            padding: 0;
        }
    }
}
