.sessions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;

    &__button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 180px;
        width: 100%;
        margin: 5px 5px 15px 20px;
    }
}