.archive {

    &__refresh-btn {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--sea);
        font-size: var(--size-s);
        font-family: 'Roboto', serif;
        margin: 0 0 40px;

        svg {
            margin: 0 12px 0 0;
        }
    }
}