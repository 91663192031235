.params__inputs {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.add-param-btn {
    color: var(--sea);
    font-weight: 500;
    font-size: var(--size-s);
    display: flex;
    align-items: center;
    transition: .3s;
    height: 40px;

    svg {
        fill: var(--sea);
        margin: 0 12px 0 0;
        transition: .3s;
    }

    &:hover {
        color: var(--mice);

        svg {
            fill: var(--mice);
        }
    }
}
