.service-list {

    .add-btn {
        margin: 30px 0 45px;
    }
}

.list-params {

    &__input-box {
        display: flex;
        align-items: inherit;
        flex-direction: column
    }

    &__inputs {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2,1fr);
        padding: 10px;

        &_transparent {

            input {
                background: var(--milk);
            }

            p {
                background: var(--milk);
            }
        }
    }

    &__checkboxes {
        flex: 1;
        padding: 10px;
        min-width: 340px;

        label {
            margin: 0 0 20px;
        }
    }
}

@keyframes inAnimation {

    0% {
        max-height: 0;
        opacity: 0;
    }

    100% {
        max-height: 500px;
        opacity: 1;
    }
}

@keyframes outAnimation {

    0% {
        max-height: 500px;
    }

    100% {
        max-height: 0;
    }
}
