
.add-btn {
    margin: 30px 0 45px;
}

.interval-edit {

    &__form {
        display: flex;
        flex-direction: column;
        padding: 0 0 20px;

        label {
            margin: 0 10px 40px;
        }
    }

    &__form-row {
        display: flex;

        .checkbox {
            max-width: 250px;
            width: 100%;

            &__content {
                flex: 1;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__interval {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 380px;
        width: 100%;
        margin: 0 10px;
    }

    &__interval-title {
        font-size: 1.125em;
        font-weight: 500;
        margin-bottom: 20px;
        color: var(--gray);
    }

    &__intervals-inputs {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__intervals-input {
        display: flex;
        width: 100%;
        align-items: center;

        label {
            margin: 0;
            max-width: initial;

        }
    }

    &__intervals-input-title {
        font-size: 0.875em;
        color: var(--gray);
        margin-right: 20px;
    }
}

.connected-days {
    display: flex;
    max-width: 280px;
    grid-row-gap: 12px;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    align-items: baseline;

    p {
        color: var(--mice);
        font-size: 12px;

        &:last-child {
            margin: 0;
        }
    }

    span {
        color: var(--gray);
    }
}

.default {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.drawerStyle .checkbox__content{
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 380px;
    background-color: #FFFFFF;
}

.drawerStyle .checkbox{
    margin: 0;
    padding: 0;
}

.drawerStyle .interval-edit__intervals-inputs{
    display: flex;
    flex-direction: column;
}

.drawerStyle .interval-edit__intervals-input input {
    width: 100px;
}

.drawerStyle .interval-edit__intervals-input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 180px;
    
}