.editable-item {
    width: 100%;
    max-width: 100%;
    transform-origin: top left;

    &__content {
        width: 100%;
    }

    &__title {
        text-align: center;
        font-size: var(--size-xs);
        color: var(--gray);
        font-weight: 500;
        padding: 30px 0 20px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
    }

    &__form-rows {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        max-width: 1180px;
        margin: 0 auto;
        gap: 20px;
    }

    &__form-row {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        max-width: 1180px;
        margin: 0 auto 40px;

        &-between {
            justify-content: space-between;
        }

        &_transparent {

            input, .select__value, .checkbox__checkmark, .multiselect__content, .filter {
                background: var(--milk);
            }

            .multiselect__search input {
                background: var(--white);
            }
        }
    }

    &__masonry {
        width: 100%;
        column-count: 3;
        column-gap: 20px;
    }

    &__form-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: baseline;
        gap: 20px;
    }

    &__grid-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        grid-column-gap: 20px;
        grid-row-gap: 40px;
        margin-bottom: 40px;

        label {
            margin: 0;
        }
    }

    &__form-btns {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 0 40px;
        margin-top: 40px;

        button {
            max-width: 320px;
            margin: 0 10px;
        }
    }

    &__card {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px;
        background: #FFFFFF;

        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #777777;
            margin-bottom: 12px;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #777777;
        }

        &-transparent {
            background: var(--milk);
        }

        &-box {
            padding: 0 10px;
        }
    }
}


.editable-item__form-row .defaultPolls{
    width: 380px;
}
.button-main{
    width: 320px;
}
.default{
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.default .editable-item__form-row{
    display: flex;
    flex-direction: column;
}

.default .field-text{
    width: 380px;
}


.ant-drawer-body{
    background-color: #f7f7f7;
}

.drawerStyle{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.drawerStyle .field-text__input {
    width: 380px;
}

.drawerStyle input{
    background-color: #FFFFFF;
}

.drawerStyle label{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 380px;
}

.drawerStyle .select__value{
    background-color: #FFFFFF;
    width: 380px;
}

.drawerStyle .list-params__input-box{
    display: flex;
    flex-direction: column;
}

.drawerStyle .list-params__checkboxes label{
    margin-bottom: 20px;
}
.drawerStyle .list-params__checkboxes .checkbox__content {
    justify-content: flex-start;
}

.drawerStyle  .checkbox__checkmark  {
   border: 1px solid black;
}

.editable-item__form-row .multiselect__content{
    width: 750px;
}