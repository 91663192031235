.account-box {
    display: flex;
    align-items: center;
}

.locale {
    display: flex;
    margin: 0 10px 0 0;

    a {
        display: flex;
        margin: 0 5px 0 0;
    }

    &__btn {
        color: var(--sea);

        &_active {
            font-weight: bold;
        }
    }

}

.account {
    display: flex;
    cursor: pointer;
    align-items: center;

    &__icon {
        margin: 0 10px 0 0;

        svg {
            fill: var(--nav);
            transition: .3s;
        }
    }

    &__name {
        display: flex;
        flex-direction: column;
        color: var(--nav);
        font-size: var(--size-xs);
        transition: .3s;
        margin-bottom: 0;
    }

    &:hover {

        .account__name {
            color: var(--mice);
        }

        .account__icon {

            svg {
                fill: var(--mice);
            }
        }

    }
}