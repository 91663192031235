.button-main {
    background: var(--sea);
    border-radius: 4px;
    height: 50px;
    width: 100%;
    color: var(--white);
    font-size: var(--size-m);
    margin-top: 50px;
    border: 0;

    &--disabled {
        opacity: 0.5;
    }
}

.add-btn {
    color: var(--sea);
    font-weight: 500;
    font-size: var(--size-s);
    display: flex;
    align-items: center;
    transition: .3s;

    svg {
        fill: var(--sea);
        margin: 0 12px 0 0;
        transition: .3s;
    }

    &:hover {
        color: var(--mice);

        svg {
            fill: var(--mice);
        }
    }
}

.export__btn {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 40px;

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 0;
        color: var(--nav);
    }
}

.small-btn {
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 20px;
    color: var(--sea);
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: .3s;

    svg {
        fill: var(--sea);
        margin: 0 12px 0 0;
        transition: .3s;
    }

    &:hover {
        color: var(--mice);

        svg {
            fill: var(--mice);
        }
    }
}

.table__btn {
    @extend .small-btn;
    justify-content: center;
    margin: 0;
    width: 100%;
}
