.table__statuses__cell {
    width: fit-content;
    display: grid;
    grid-template: auto/auto auto;
    column-gap: 20px;
}

.table__statuses__cell > p {
    width: fit-content;
}

.substatus__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    gap: 10px;
    background: #FFFFFF;
    padding: 10px 20px;
    border-radius: 5px;
}