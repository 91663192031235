.report_filters {

    p {
        margin-bottom: 0;
    }

    &__title {
        text-align: center;
        font-size: var(--size-s);
        color: var(--gray);
        font-weight: 500;
        margin-bottom: -30px;
    }

    &__box {
        display: flex;
        flex-direction: column;

        margin-right: 40px;
        margin-bottom: 40px;
        gap: 40px;
        
    }

    &__dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 380px;
        font-size: 14px;
        color: var(--gray);

        &-wrapper {
            width: fit-content;
            height: 106px;
        }

        &__range {
            outline: none;
            border: none;
            height: 100%;

            input {
                text-align: center;
                font-size: 14px;
                line-height: 16px;
                color: #777777;
            }
        }
    }

    &__calendar {
        background-color: var(--white);
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }

    &__btn {
        width: fit-content;
        margin: 40px auto 0;

        &-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #37798E;
        }
    }

    &__input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 10px;
        width: 380px;

        &-text {
            width: 80px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #777777;
            text-align: end;
            max-width: 90px;
            margin-bottom: 0;
        }

        &-date {
            background-color: #FFF;
            width: 264px;
            height: 48px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }
    }

    &__secondary {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 40px auto 0;
    }

    &__main {
        display: flex;
        width: 100%;
        gap: 10px;
    }
}
