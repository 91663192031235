.reports {

    .tabs {

        &__tab {
            padding: 20px 6px;
        }

        &__main {
            padding: 0 14px;
            margin-bottom: 30px;
        }
    }
}

.report_table__btn {
    font-family: 'Ubuntu', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37798E;

    &:hover {
        cursor: pointer;
        color: #3F3F3F;
    }
}
