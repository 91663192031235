.multiselect {
    width: 100%;

    &__content {
        padding: 20px 0;
        background: var(--white);
        min-height: 300px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 20px;
    }

    &__top-btns {
        display: flex;
    }

    &__top-btn {
        display: flex;
        align-items: center;
        font-size: 1.25em;
        color: var(--sea);

        svg {
            margin-right: 12px;
        }

        &:first-child {
            margin-right: 40px;
        }
    }

    &__search {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 280px;
        width: 100%;

        input {
            background: var(--milk);
            height: 30px;
            border-radius: 4px;
            width: 100%;
            border: 0;
            padding: 0 45px 0 10px;
        }

        svg {
            position: absolute;
            right: 20px;
        }
    }

    &__body {
        display: grid;
        grid-row-gap: 20px;
        grid-template-columns: 220px 220px 220px;
        grid-column-gap: 20px;
        overflow-y: scroll;
        min-width: 0;
        height: 220px;
        padding: 0 10px 10px;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 4px 4px rgba(119, 119, 119, 0.3);
            width: 100%;
            height: 56px;
            padding: 12px 25px;
            word-wrap: break-word;
            background: var(--white);
        }

        .active {
            border: 2px solid var(--sky);
        }
    }
}
