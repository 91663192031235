.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 50px;

    &__box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-direction: column;
        gap: 100px;
    }

    &__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 50px;
        max-width: 1180px;
        margin: 0 auto;
    }

    &__bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    &__left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        &--news {
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-end;
            gap: 50px;
            max-width: 1180px;
            margin: 0 auto;
        }
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__video {
        width: 360px;
        height: 200px;
        background: #000000FF;
    }

    &__filtration {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

}