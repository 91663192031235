.content {

    &__dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 14px;
        color: var(--gray);
    }

    &__dates-title {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 500;
        color: var(--gray);
        font-size: 16px;
    }

    &__dates-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__calendar {
        background-color: var(--white);
        width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }
}