.report {

    &__top {
        display: flex;
        gap: 20px;
        max-width: 1180px;
        margin: 0 auto;
    }

    &__card {
        display: grid;
        grid-template-columns: 3fr 1fr;
        width: 100%;
        max-width: 394px;
        background-color: var(--white);
        padding: 14px 20px 40px;

        &__text {
            font-family: 'Ubuntu', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: var(--gray);
            margin-top: 26px;
            margin-bottom: 0;

            &-prim {
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
            }

            &__value {
                font-family: 'Ubuntu', serif;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 25px;
                color: var(--gray);
                text-align: end;
                margin-top: 26px;
                margin-bottom: 0;

                &-prim {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
        }
    }

    &__graph {
        width: 100%;
        background-color: var(--white);
        padding: 16px 20px;
    }

    &__section {
        background-color: var(--white);
        margin-top: 60px;
        padding: 20px;
        width: 100%;

        &-table {
            background: none;
        }
    }

    &__table {

        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            &__cell {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
            }
        }
    }
}
