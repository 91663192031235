.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--lightgray);

    &__box {
        max-width: 580px;
        width: 100%;
        background: var(--white);
        padding: 40px 20px;
        box-shadow: 0 8px 20px #ADD1DC;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;
        height: auto;
        > img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100% - 104px);
    }

    &__title {
        text-align: center;
        color: var(--darkgray);
        font-size: var(--size-s);
        margin: 0 0 0 40px;
    }

    &__form {
        display: flex;
        max-width: 380px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px auto 0;

        .field-text input {
            background: var(--milk);
        }

        .field-text {
            margin: 0 0 24px;
        }
    }

    &__form-title {
        font-size: var(--size-xxl);
        color: var(--gray);
        margin: 0 0 40px;
    }
}
