.dashboard {

    &__top {
        display: flex;
        margin: 0 auto 40px;
        gap: 20px;
        width: fit-content;
    }

    &__graph {
        width: 780px;
        background: var(--white);
        padding: 20px 16px;

        &__title {
            font-size: 24px;
            line-height: 28px;
            color: var(--gray);
            text-align: center;
        }
    }

    &__table {
        padding: 0 0 60px;
    }

    &__table-title {
        font-size: var(--size-xl);
        color: var(--gray);
        text-align: center;
        margin: 0 0 20px;
    }

    &__card {
        background: var(--white);
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 380px;
        padding: 20px;

        &__text {

            &:nth-child(even) {
                text-align: end;
            }

            &-prim {
                font-size: 24px;
                line-height: 28px;
                color: var(--gray);
                margin-bottom: 14px;
            }

            &-green {
                color: var(--green);
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 14px;
            }

            &-orange {
                color: var(--orange);
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 26px;
            }
        }
    }

    &__time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        margin-bottom: 60px;

        &__item {
            width: 100%;
            max-width: 380px;

            &__header {
                font-size: 24px;
                line-height: 28px;
                color: var(--gray);
                text-align: center;
                margin-bottom: 20px;
            }

            &__value {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--white);
            }

            &__text {
                font-size: 23px;
                line-height: 25px;
                color: var(--gray);
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
}