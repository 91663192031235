.operatorHideWidgetsBtn {
    margin: 0 auto;
}

.hidden {
    visibility: hidden;
}

.values {
    height: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-sizing: border-box;

    &-enabled {
        border: solid 1px;
    }
}
