.date-time {
    width: 100%;
    height: 48px;
    max-width: 264px;

    &-picker {
        width: 100%;
        height: 100%;
        text-align: center;

        input {
            font-family: 'Ubuntu', serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #777777;
            text-align: center;
        }
    }
}
