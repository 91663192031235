.doughnut {
    position: relative;

    &__text {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #777777;
        position: absolute;
        left: 24%;
        top: 45%;
    }
}
